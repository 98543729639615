import { Instagram, Facebook } from 'lucide-react';

import { Link } from '@remix-run/react';
import { privacyPolicyRoute, tosRoute } from '@web/routes/public';
import { cn } from '@web/utilities/styles';

import { LogoWebsite } from '../Logo/LogoWebsite';

import DiscordIconWhite from './discord-icon-white.svg';
import TwitterIcon from './x-twitter-icon.svg';

export const Footer = ({ isAdvertiser }: { isAdvertiser?: boolean }) => {
  return (
    <footer
      className={cn(
        'px-4 pt-10 sm:px-10 sm:pt-10',
        isAdvertiser ? 'bg-slate-800' : 'bg-slate-100'
      )}
    >
      <div className="mx-auto max-w-[1520px]">
        <div className="flex items-center justify-between sm:items-center sm:justify-between">
          <div className="sm:flex sm:flex-col">
            <LogoWebsite
              className="h-[25px] w-[105px] sm:h-[29px] sm:w-[121px]"
              variant={isAdvertiser ? 'whiteText' : 'blackText'}
            />
            <p
              className={cn(
                'hidden sm:mt-6 sm:block sm:text-left sm:font-inter sm:text-xl sm:font-normal',
                isAdvertiser ? 'text-slate-400' : 'text-slate-600'
              )}
            >
              Onezee 2024, All rights reserved.
            </p>
          </div>
          <div>
            <div className="mb-4 flex items-center justify-center gap-2 sm:gap-4">
              {!isAdvertiser && (
                <a
                  href="https://discord.gg/eRHuwwzSzz"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Join us on Discord"
                  className="flex size-10 items-center justify-center rounded-full bg-violet-500 hover:bg-violet-600"
                >
                  <img
                    src={DiscordIconWhite}
                    alt="Discord Icon"
                    className="size-6"
                  />
                </a>
              )}
              <a
                href="https://instagram.com/onezee_io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on Instagram"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500 hover:bg-violet-600"
              >
                <Instagram className="size-6 text-white" />
              </a>
              <a
                href="https://facebook.com/onezee.io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Like us on Facebook"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500 hover:bg-violet-600"
              >
                <Facebook className="size-6 text-white" />
              </a>
              <a
                href="https://x.com/Onezee_io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on Twitter"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500 hover:bg-violet-600"
              >
                <img src={TwitterIcon} alt="Twitter Icon" className="size-6" />
              </a>
            </div>

            <div>
              <ul
                className={cn(
                  'flex text-sm tracking-wider',
                  isAdvertiser ? 'justify-center' : 'justify-between'
                )}
              >
                {!isAdvertiser && (
                  <li className="inline-block">
                    <a
                      href={tosRoute()}
                      className="text-violet-500 hover:text-violet-600 hover:underline"
                      title="Terms of service"
                    >
                      Terms of service
                    </a>
                  </li>
                )}

                <li
                  className={cn(
                    'inline-block',
                    !isAdvertiser ? 'ml-4' : 'ml-0'
                  )}
                >
                  <Link
                    to={privacyPolicyRoute()}
                    className="text-violet-500 hover:text-violet-600 hover:underline"
                    title="Privacy policy"
                  >
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="mt-6 pb-4 text-center font-inter text-xs font-normal text-slate-600 sm:hidden">
          Onezee 2024, All rights reserved.
        </p>
        <div className="hidden sm:block sm:h-10"></div>
      </div>
    </footer>
  );
};
